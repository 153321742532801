/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import "./layout.css"
import styles from "./layout.module.css";
import logo from "../images/logo.svg";

const Layout = ({children}) => {
  return (
    <>
      {/*<!-- Google Tag Manager (noscript) -->*/}
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PZG4HC5"
          height="0" width="0" style={{display:'none',visibility:'hidden'}} title="googletagmanager_pymes_eldiario"></iframe>
      </noscript>
      {/*<!-- End Google Tag Manager (noscript) -->*/}
      <header className={styles.header}>
        <Link to="/">
          <img src={logo} alt="logo de elDiario.es"/>
        </Link>
      </header>
      <main>{children}</main>
      <footer className={styles.footer}>
        <img src={logo} alt="logo de elDiario.es"/>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
